import React, { FC, useEffect, useState, MutableRefObject } from 'react';
import cn from 'classnames';
import Icon from 'src/components/Icon';

interface Props {
  scrollableRef: MutableRefObject<HTMLElement | null>;
  hideUntil?: number;
}

const BackToTopButton: FC<Props> = ({ scrollableRef, hideUntil = 10 }) => {
  const [hideButton, setHideButton] = useState(true);
  useEffect(() => {
    if (!scrollableRef.current) return;
    const { current: scrollable } = scrollableRef;
    const calculateButtonVisibility = () => {
      const currentScroll = (scrollable.scrollTop / (scrollable.scrollHeight - scrollable.clientHeight)) * 100;
      if (currentScroll >= hideUntil) {
        setHideButton(false);
      } else {
        setHideButton(true);
      }
    };
    scrollable.addEventListener('scroll', calculateButtonVisibility);
    return () => {
      scrollable.removeEventListener('scroll', calculateButtonVisibility);
    };
  }, [scrollableRef, scrollableRef.current]);
  function scrollToTop() {
    if (scrollableRef.current) {
      scrollableRef.current.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
  return (
    <button className={cn('back-to-top-button', { hide: hideButton })} onClick={scrollToTop}>
      <style jsx={true}>{`
        .back-to-top-button {
          @p: .pa10, .bgBlack30, .white;

          position: fixed;
          bottom: 20px;
          right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 300ms ease;
          border-radius: 50%;
          border: 0;
          z-index: 10000;
        }
        .back-to-top-button:hover {
          @p: .bgBlack;
        }
        .back-to-top-button.hide {
          display: none;
        }
        @media (min-width: 500px) {
          display: none !important;
        }
      `}</style>
      <Icon src={require('../../icons/fill/arrow-up.svg')} width={20} height={20} color="#fff" />
    </button>
  );
};

export default BackToTopButton;
