import React, { useState } from 'react';
import cn from 'classnames';
import api from 'src/api';
import LinkButton from 'src/components/common/LinkButton';

function validateEmail(email: string): boolean {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; /* tslint:disable-line */
  return regex.test(email);
}

interface Props {
  light?: boolean;
  audienceId: string;
}

const Subscribe: React.FC<Props> = ({ audienceId, light }) => {
  const [email, setEmail] = useState('');
  const [isSending, setSending] = useState(false);
  const [isSent, setSent] = useState(false);

  if (isSent) {
    return (
      <p className={cn({ light })}>
        {' '}
        <style jsx={true}>{`
          p {
            @p: .tc;
          }
          p.light {
            @p: .white;
          }
        `}</style>
        Thanks for signing up. We will inform you as soon as the course is ready!
      </p>
    );
  }

  const handleEmailChanged: React.FormEventHandler<HTMLInputElement> = e => {
    setEmail(e.currentTarget.value);
  };

  const onKeyDown: React.KeyboardEventHandler = e => {
    if (e.keyCode !== 13) return;
    submit();
  };

  const submit = () => {
    if (!validateEmail(email)) return;
    setSending(true);
    api
      .subscribe(audienceId, email)
      .then(() => {
        setSent(true);
      })
      .finally(() => {
        setSending(false);
      });
  };

  const renderStayTuneButton = () => (
    <LinkButton rounded={true} bold={true} onClick={submit} style={{ whiteSpace: 'nowrap' }}>
      {isSending ? 'Submitting...' : 'Stay Tuned'}
    </LinkButton>
  );

  return (
    <div>
      <style jsx={true}>{`
        .subscribe {
          @p: .bgWhite04, .br2, .pa10, .flex, .center;
          max-width: 512px;
        }
        input {
          @p: .w100, .pl10, .white80;
          border: none;
          background: none;
          font-size: 18px;
        }
        .subscribe.light {
          @p: .bgDarkGreen10;
        }
        .subscribe.light input {
          @p: .darkGreen80;
        }
        p {
          @p: .tc, .mb16, .white80, .lhCopy;
        }
        @media (max-width: 580px) {
          div p {
            @p: .ph25;
          }
          div input {
            @p: .pa10, .mb10;
          }
          div.subscribe {
            @p: .db;
          }
          .subscribe {
            background-color: transparent !important;
          }
          .subscribe input {
            @p: .bgWhite04;
          }
        }
      `}</style>
      <p>Sign up here to get notified when this course is available</p>
      <div className={cn('subscribe', { light })}>
        <input type="text" placeholder="Enter your Email" onChange={handleEmailChanged} onKeyDown={onKeyDown} />
        {renderStayTuneButton()}
      </div>
    </div>
  );
};

export default Subscribe;
