import React, { FC, useState } from 'react';
import LinkButton from 'src/components/common/LinkButton';
import SubscribeInfoModal from './SubscribeInfoModal';

interface SubscribePremiumButtonProps {
  user: any;
}

const SubscribePremiumButton: FC<SubscribePremiumButtonProps> = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <>
      <LinkButton
        size="large"
        rounded={true}
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Subscribe for $4.99
      </LinkButton>
      <SubscribeInfoModal open={isModalOpen} close={() => setIsModalOpen(false)} />
    </>
  );
};

export default SubscribePremiumButton;
