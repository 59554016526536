import React from 'react';
import withWidth from './home/withWidth';
import cn from 'classnames';
import SwipeableViews from 'react-swipeable-views';
import { ComingSoon } from 'src/components/ComingSoon';
import { CourseDataFragment } from 'types/graphql-types';

interface Props {
  courses: CourseDataFragment[];
  selectedIndex: number;
  onChangeSelectedIndex: (n: number) => void;
  width?: number;
  fixedWidth?: number;
  showSelectedBorder?: boolean;
  onClickCurrentStack?: () => void;
}

class StackChooser extends React.Component<Props, {}> {
  mouseMoved: boolean = false;
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }
  render() {
    const { courses, selectedIndex, onChangeSelectedIndex, showSelectedBorder = false } = this.props;

    // const widthElement = 140 + 20
    // const widthElementSelected = 140 + 80
    //
    // const translateWidth = (fixedWidth > 0 ? fixedWidth : width) || 1
    // const translateX =
    //   translateWidth / 2 - widthElement * selectedIndex - widthElementSelected / 2

    return (
      <div className="stack-chooser">
        <style jsx={true}>{`
          .stack-chooser {
            @p: .overflowHidden;
          }
          .stacks-content {
            @p: .flex, .center, .relative, .mv12;
            left: 4px;
            max-width: 200px;
            align-items: center;
          }
          div :global(.stacks) {
            @p: .flex;
            transition: transform 0.2s ease-out;
            align-items: center;
          }
          .stacks-item {
            @p: .tc, .pointer, .center;
            transition: all 0.1s ease-out;
            user-select: none;
            width: 140px;
          }
          .stacks-item p {
            @p: .white;
          }
          .stacks-item :global(i),
          .stacks-item img {
            @p: .o30;
            filter: grayscale(100%) brightness(200%);
          }
          .stacks-item img {
            @p: .mv0;
            max-width: 128px;
          }
          .stacks-item p {
            @p: .mt10, .o40, .f14, .fw6;
          }
          .stacks-item.active {
            transform: scale(1.2);
          }
          .stacks-item.active.showSelectedBorder {
            @p: .ba, .bw2;
            border-color: rgb(229, 229, 229);
            border-radius: 6px;
          }
          .stacks-item.active :global(i),
          .stacks-item.active img {
            @p: .o100;
            filter: grayscale(0%);
          }
          .stacks-item.active p {
            @p: .o100;
          }
          .logos {
            @p: .flex, .justifyCenter;
          }
          .logos :global(i) + :global(i),
          .logos :global(i) + :global(img),
          .logos :global(img) + :global(i) {
            @p: .mv0;
            margin-left: 10px !important;
          }
          .beginners-choice {
            @p: .f10, .fw6, .ttu, .tc, .mt10, .tracked;
            color: #459bf2;
          }
          img {
            width: auto !important;
            height: 50px;
            margin-left: 0 !important;
          }
          .coming-soon-container {
            @p: .mt10;
          }

          @media (max-width: 500px) {
            .stacks-content {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        `}</style>
        <div className="stacks-content">
          <SwipeableViews
            enableMouseEvents={true}
            style={{
              overflow: 'visible',
              width: 190,
            }}
            slideStyle={{
              padding: '16px 0',
              display: 'flex',
              alignItems: 'flex-start',
            }}
            index={selectedIndex}
            onChangeIndex={onChangeSelectedIndex}
          >
            {courses.map((course, index) => (
              <div
                className={cn('stacks-item', {
                  active: selectedIndex === index,
                  showSelectedBorder,
                })}
                onMouseDown={this.handleMouseDown}
                onDrag={this.handleDrag}
                onMouseUp={this.handleMouseUp.bind(this, index)}
                key={index}
              >
                <div className="logos">
                  <img alt="" src={course.logo || undefined} />
                </div>
                <p>{course.name}</p>
                {course.comingSoon && (
                  <div className="coming-soon-container">
                    <ComingSoon />
                  </div>
                )}
              </div>
            ))}
          </SwipeableViews>
        </div>
      </div>
    );
  }
  private handleMouseDown = () => {
    this.mouseMoved = false;
  };
  private handleDrag = () => {
    this.mouseMoved = true;
  };
  private handleMouseUp = (i: number) => {
    if (!this.mouseMoved) {
      this.props.onChangeSelectedIndex(i);
      if (i === this.props.selectedIndex && typeof this.props.onClickCurrentStack === 'function') {
        this.props.onClickCurrentStack();
      }
    }
    this.mouseMoved = false;
  };
  private handleKeydown = (e: any) => {
    if (typeof HTMLInputElement !== 'undefined' && e.target instanceof HTMLInputElement) {
      return;
    }
    if (e.keyCode === 37) {
      this.props.onChangeSelectedIndex(this.props.selectedIndex - 1);
    } else if (e.keyCode === 39) {
      this.props.onChangeSelectedIndex(this.props.selectedIndex + 1);
    }
  };
}

export default withWidth<Props>()(StackChooser);
