import * as React from 'react';

export default () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.2"
      d="M0.292893 8.29289C-0.0976311 8.68342 -0.0976311 9.31658 0.292893 9.70711C0.683417 10.0976 1.31658 10.0976 1.70711 9.70711L0.292893 8.29289ZM9 1H10C10 0.447715 9.55229 0 9 0V1ZM1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM8 9C8 9.55229 8.44772 10 9 10C9.55229 10 10 9.55229 10 9H8ZM1.70711 9.70711L9.70711 1.70711L8.29289 0.292893L0.292893 8.29289L1.70711 9.70711ZM1 2H9V0H1V2ZM8 1V9H10V1H8Z"
      fill="#000"
    />
  </svg>
);
