import React from 'react';
import OptionalSteps from 'src/components/Steps/OptionalSteps';
import Steps from 'src/components/Steps/Steps';
import { useExtractedSteps } from '../CourseProvider';

interface Props {
  dark?: boolean;
  first?: boolean;
}

const CourseSteps: React.FC<Props> = ({ dark, first = true }) => {
  const extractedSteps = useExtractedSteps();
  return (
    <div className="steps-list fade-before">
      <style jsx={true}>{`
        .steps-list {
          @p: .mhAuto, .w100;
        }
        @media (max-width: 500px) {
          .steps-list :global(.steps-description) {
            display: none;
          }
          .steps-list {
            @p: .w100;
            padding-left: 20px;
          }
        }
      `}</style>
      {extractedSteps?.map((group, i) => {
        if (group.t === 'Lesson') {
          return (
            <Steps
              key={i}
              first={false}
              last={false}
              dark={dark}
              showDescription={true}
              description={group.description}
              steps={group.steps}
              highlightFirst={true}
              showLast={true}
              showDuration={!first}
            />
          );
        } else {
          return (
            <OptionalSteps
              dark={dark}
              showDescription={true}
              description={group.description}
              steps={group.steps}
              small={true}
            />
          );
        }
      })}
    </div>
  );
};

export default CourseSteps;
