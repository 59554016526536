import React from 'react';
import $v from 'src/variables';

interface BendLineProps {
  reverse?: boolean;
  dark?: boolean;
  className?: string;
  fade?: 'start' | 'end' | 'none';
}

const BendLine: React.FC<BendLineProps> = ({ className, dark, reverse = false, fade = 'none' }) => {
  return (
    <svg style={{ paddingLeft: '20px', boxSizing: 'border-box' }} className={className} viewBox="0 0 100 60">
      <defs>
        <linearGradient id="start-fade">
          <stop offset="0" stopColor="black" />
          <stop offset="0.05" stopColor="white" />
        </linearGradient>
        <linearGradient id="end-fade">
          <stop offset="0.95" stopColor="white" />
          <stop offset="1" stopColor="black" />
        </linearGradient>
        <mask id="start-fade-mask">
          <rect x="0" y="0" width="100" height="60" fill="url(#start-fade)" />
        </mask>
        <mask id="end-fade-mask">
          <rect x="0" y="0" width="100" height="60" fill="url(#end-fade)" />
        </mask>
      </defs>
      <path
        transform={reverse ? 'translate(100), scale(-1, 1)' : ''}
        style={{
          fill: 'none',
          stroke: dark ? $v.white30 : $v.gray10,
          strokeWidth: 1,
          maskRepeat: 'no-repeat',
        }}
        mask={fade === 'start' ? 'url(#start-fade-mask)' : fade === 'end' ? 'url(#end-fade-mask)' : undefined}
        d="m0,0 c0,15,0,30,30,30 l40,0 c30,0,30,15,30,30"
      />
    </svg>
  );
};

export default BendLine;
