import React, { FC, useState } from 'react';
import { useAuth0 } from 'src/utils/auth';
import { usePayment } from 'src/utils/Stripe';
import LinkButton from './common/LinkButton';
import cn from 'classnames';
import api from 'src/api';

interface SubscribeModalInfoProps {
  open: boolean;
  close?: () => void;
}

const SubscribeModalInfo: FC<SubscribeModalInfoProps> = ({ open, close = () => {} }) => {
  const { buy, loading } = usePayment();
  const { user } = useAuth0();
  const [userInputCoupon, setUserInputCoupon] = useState<string>('');
  const [validateCouponLoading, setValidateCouponLoading] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState<boolean | null>(null);
  const [couponName, setCouponName] = useState('');

  const validateCoupon = async () => {
    if (validateCouponLoading) return;
    setValidateCouponLoading(true);
    setIsCouponValid(null);
    setCouponName('');
    try {
      const {
        data: { is_valid: isValid, name },
      } = await api.validateCoupon({ coupon: userInputCoupon });
      setIsCouponValid(isValid);
      setCouponName(name);
    } catch (error) {
      // PASS
    } finally {
      setValidateCouponLoading(false);
    }
  };

  const subscribePremium = () => {
    if (validateCouponLoading) return;
    const shouldContainCoupon = userInputCoupon && isCouponValid;
    const containedCoupon = shouldContainCoupon ? userInputCoupon : undefined;
    return buy(user.sub, containedCoupon);
  };

  return (
    <div className={cn('modal-wrapper', { visible: open })}>
      <style jsx={true}>
        {`
          .modal-wrapper {
            @p: .zMax;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: none;
            background-color: rgba(0, 0, 0, 0.5);
          }
          .modal-wrapper.visible {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .modal-content {
            @p: .pa38, .bgWhite;
            position: relative;
            border-radius: 5px;
          }
          .modal-cross {
            position: absolute;
            top: 10px;
            left: 15px;
            font-size: 120%;
          }
          .modal-cross:hover {
            cursor: pointer;
          }
          .modal-header {
            @p: .mb25;
          }
          .modal-title {
            @p: .f25;
          }
          .modal-sub-title {
            @p: .mt6;
          }
          .coupon {
            @p: .mb25;
          }
          .coupon-input-container {
            @p: .mb6;
            display: flex;
            align-items: center;
          }
          .coupon-not-found {
            @p: .red;
            font-size: 70%;
          }
          .coupon-name {
            font-size: 80%;
          }
          .coupon-input {
            @p: .mr6, .pv6, .ph10, .bgBlack10;
            width: 50%;
            border: none;
            font-size: 70%;
            border-radius: 2px;
          }
          @media (max-width: 500px) {
            .modal-wrapper {
              @p: .pa10;
            }
            .modal-title.modal-title {
              @p: .f16;
            }
            .modal-sub-title {
              @p: .f14;
            }
            .coupon-input-container {
              flex-direction: column;
              align-items: flex-start;
            }
            .coupon-input {
              @p: .mb6;
              width: 100%;
            }
          }
        `}
      </style>
      <section className="modal-content">
        <div className="modal-cross" onClick={close}>
          ×
        </div>
        <header className="modal-header">
          <h2 className="modal-title">Subscribe to Cauzl</h2>
          <p className="modal-sub-title">$0.99 per month</p>
        </header>
        <div className="coupon">
          <div className="coupon-input-container">
            <input
              className="coupon-input"
              type="text"
              placeholder="Enter a coupon"
              value={userInputCoupon}
              onChange={e => setUserInputCoupon(e.target.value)}
            />
            <LinkButton size="small" type="secondary" rounded={true} onClick={validateCoupon}>
              {validateCouponLoading ? 'Checking...' : 'Add Coupon'}
            </LinkButton>
          </div>
          {isCouponValid === false && <p className="coupon-not-found">Coupon is not valid!</p>}
          {isCouponValid && couponName && <p className="coupon-name">{couponName}</p>}
        </div>
        <LinkButton onClick={subscribePremium} rounded={true}>
          {loading ? 'Processing...' : 'Proceed'}
        </LinkButton>
      </section>
    </div>
  );
};

export default SubscribeModalInfo;
