import * as React from 'react';
import { connect } from 'react-redux';
import { setOverviewVideoVisible } from 'src/actions/ui';
import AuthButtonImpl from '../misc/AuthButtonImpl';
import CourseSteps from './CourseSteps';
import { CourseProvider } from '../CourseProvider';
import { Course } from 'src/types';
import Chooser from './Chooser';
import FakeLine from '../Steps/FakeLine';
import BendLine from '../Steps/BendLine';
import { Banner } from 'src/components/Banner';
import css from 'styled-jsx/css';

const fakeLineStyle = css.resolve`
  @media (max-width: 500px) {
    .fake-line {
      padding-left: 20px;
    }
    .fake-line.start {
      display: none;
    }
  }
`;

interface OwnProps {
  setOverviewVideoVisible: (visible: boolean) => void;
  introCourse: Course;
}

const Intro = (props: OwnProps) => {
  return (
    <section className="intro">
      {fakeLineStyle.styles}
      <style jsx={true}>{`
        .intro {
          @p: .pt38;
        }
        .rest {
          @p: .relative, .z2;
        }
        section {
          @p: .relative;
        }
        .heading-title {
          @p: .tc, .rozha-font;
          line-height: 1;
          font-size: 500%;
          font-weight: 100;
        }
        .heading-sub {
          @p: .mv38, .center, .tc;
          max-width: 800px;
          font-size: 130%;
        }
        .watch-overview {
          @p: .black40, .f16, .fw6, .mt25, .pa16, .ttu, .flex, .itemsCenter, .pointer;
          transition: color 0.15s ease-in-out;
        }
        .watch-overview:hover {
          @p: .black60;
        }
        .watch-overview img {
          transition: filter 0.15s ease-in-out;
        }
        .watch-overview :global(svg) > :global(g) {
          transition: opacity 0.15s ease-in-out;
        }
        .watch-overview:hover :global(svg) > :global(g) {
          opacity: 0.6;
        }
        .watch-overview span {
          @p: .ml16;
        }
        .btn-container {
          @p: .flex, .justifyCenter;
        }
        @media (max-width: 500px) {
          .heading {
            padding: 0 30px;
          }
          .heading-title {
            @p: .f60;
          }
          .heading-sub {
            @p: .mv25, .f20;
            line-height: 1.3;
          }
        }
      `}</style>
      <div className="rest">
        <Banner />
        <div className="heading">
          <h1 className="heading-title">./cauzl</h1>
          <p className="heading-sub">Learn causal inference, econometrics, and statistics!</p>
          <div className="btn-container">
            <AuthButtonImpl />
          </div>
        </div>
        <CourseProvider course={props.introCourse}>
          <BendLine fade="start" className="bend-line" reverse={true} />
          <FakeLine className={'start ' + fakeLineStyle.className} fadeBefore={true} />
          <CourseSteps />
        </CourseProvider>
        <FakeLine className={fakeLineStyle.className} />
        <Chooser />
      </div>
    </section>
  );
};

export default connect(null, { setOverviewVideoVisible })(Intro);
