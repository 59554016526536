import React, { FC } from 'react';
import cn from 'classnames';

interface IFakeLineProps {
  fadeBefore?: boolean;
  fadeAfter?: boolean;
  dark?: boolean;
  className?: string;
}
const FakeLine: FC<IFakeLineProps> = ({ fadeAfter = false, fadeBefore = false, dark = false, className = '' }) => {
  return (
    <div
      className={cn('fake-line', {
        'fade-after': fadeAfter,
        'fade-before': fadeBefore,
        [className]: true,
        dark,
      })}
    >
      <style jsx={true}>{`
        .fake-line {
          @p: .w100, .flex;
        }
        .fake-line .description {
          @p: .flex1;
        }
        .fake-line .line {
          @p: .flex1, .pv25, .bl, .bw2, .bWhite20;
        }
        .fake-line:not(.dark) .line {
          @p: .bBlack20;
        }
        .fake-line.dark .line {
          @p: .bWhite20;
        }
        .fake-line.fade-after.dark .line {
          border-image: linear-gradient(to bottom, $white20, transparent) 1 100%;
        }
        .fake-line.fade-after:not(.dark) .line {
          border-image: linear-gradient(to bottom, $gray20, transparent) 1 100%;
        }
        .fake-line.fade-before.dark .line {
          border-image: linear-gradient(to bottom, transparent, $white20) 1 100%;
        }
        .fake-line.fade-before:not(.dark) .line {
          border-image: linear-gradient(to bottom, transparent, $gray20) 1 100%;
        }
        @media (max-width: 500px) {
          .fake-line .description {
            flex: 0 !important;
          }
          .fake-line .line.line {
            @p: .pv16;
          }
        }
      `}</style>
      <div className="description" />
      <div className="line" />
    </div>
  );
};

export default FakeLine;
