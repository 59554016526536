import React from 'react';
import { Step } from 'src/types';
import { Link } from 'gatsby';
import DottedListItem from './DottedListItem';
import Icon from 'src/components/Icon';
import Duration from 'src/components/Duration';
import cn from 'classnames';
import { animated, config, useTransition } from 'react-spring';
import { useLocation } from 'src/utils/hooks';
import { IStepDescription } from '../CourseProvider';
import StepDescription from './StepDescription';
import $v from 'src/variables';
import css from 'styled-jsx/css';

const stepsItemStyle = css.resolve`
  .steps-item + .steps-item {
    @p: .mt16;
  }
`;

interface Props {
  steps: Step[];
  small?: boolean;
  description?: IStepDescription;
  showDescription?: boolean;
  showDuration?: boolean;
  dark?: boolean;
  onClickLink?: () => void;
}

const OptionalSteps = ({
  steps,
  small,
  description,
  showDescription,
  onClickLink,
  dark = false,
  showDuration = true,
}: Props) => {
  const location = useLocation();
  const isStepActive = (step: Step) => step.link === location.pathname;

  const collapsable = steps.length > 2;
  const [collapsed, setCollapsed] = React.useState(() => {
    return !collapsable || steps.some(isStepActive) ? false : true;
  });

  const visibleSteps = collapsed ? steps.slice(0, 2) : steps;
  const animatedSteps = useTransition(visibleSteps, step => step.link, {
    initial: { opacity: 1, transform: 'translateY(0px)' },
    from: { opacity: 0, transform: 'translateY(-10px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' },
    trail: collapsed ? 0 : 100,
    // @ts-ignore
    config: (item, state) => {
      return state === 'leave' ? { duration: 0 } : config.default;
    },
  });

  const toggleCollapse = () => {
    if (!collapsable) return;
    setCollapsed(c => !c);
  };

  return (
    <div className={cn('optional-steps', { small, dark })}>
      {stepsItemStyle.styles}
      <style jsx={true}>{`
        .optional-steps {
          @p: .flex, .flexRow, .w100;
        }
        .steps-container {
          @p: .flex1, .flex, .bl, .bw2, .relative, .bbox;
        }
        .steps-item + .steps-item {
          @p: .mt16;
        }
        .optional-steps.dark .steps-container {
          @p: .bWhite20;
        }
        .optional-steps .steps-container {
          @p: .bBlack20;
        }
        .desc-container {
          @p: .flex1;
        }
        p {
          @p: .black30;
        }
        .list-item {
          @p: .itemsCenter, .flex;
        }
        .list-title {
          @p: .toe, .nowrap, .mw100;
        }
        .list-title.active {
          @p: .primary;
        }
        .time {
          @p: .ttu, .black30, .ml10, .itemsCenter, .flex, .fw6, .relative;
          font-size: 15px;
        }
        .time img {
          width: 15px;
          height: 15px;
        }
        .time span {
          @p: .ml6;
        }
        .time.first {
          top: -3px;
        }
        .steps {
          @p: .flex1;
          margin-bottom: 65px;
        }
        .more {
          @p: .flex, .itemsCenter, .mt12, .pointer;
        }
        .more span {
          @p: .f20;
        }
        .small .more span {
          @p: .f16;
        }
        .plus {
          @p: .ba, .br100, .flex, .itemsCenter, .justifyCenter, .bw2, .relative;
          left: -16px;
          width: 28px;
          min-width: 28px;
          height: 28px;
        }
        .optional-steps.dark .plus {
          @p: .bgDarkGreen, .bWhite20;
        }
        .optional-steps:not(.dark) .plus {
          @p: .bBlack20, .bgWhite;
        }
        .optional-steps.dark .more {
          @p: .white20,.bgDarkGreen;
        }
        @media (max-width: 500px) {
          .desc-container {
            display: none;
          }
        }
      `}</style>
      {showDescription ? (
        <div className="desc-container">
          {!!description && <StepDescription dark={dark} description={description} />}
        </div>
      ) : null}
      <div className="steps-container">
        <div className="steps">
          {animatedSteps.map(({ item: step, key, props }, i) => {
            const actualStep = steps[i];
            const isActive = isStepActive(step);
            return (
              <animated.div key={key} style={props} className={'steps-item ' + stepsItemStyle.className}>
                <Link to={actualStep.link} onClick={onClickLink}>
                  <DottedListItem done={actualStep.completed} light={!dark} active={isActive}>
                    <div className="list-item">
                      <span className={cn('list-title', { active: isActive })}>{actualStep.title}</span>
                      {showDuration && actualStep.duration && <Duration dark={dark} duration={actualStep.duration} />}
                    </div>
                  </DottedListItem>
                </Link>
              </animated.div>
            );
          })}
          {collapsable ? (
            <div className="more" onClick={toggleCollapse}>
              <div className="plus">
                <Icon
                  src={collapsed ? require('src/icons/stroke/addFull.svg') : require('src/icons/stroke/arrowTop.svg')}
                  color={dark ? $v.white40 : $v.gray20}
                  stroke={true}
                  strokeWidth={8}
                  width={16}
                  height={16}
                />
              </div>
              {collapsed ? <span>+{steps.length - 2} more lessons</span> : 'Show Less'}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default OptionalSteps;
