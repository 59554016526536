import React, { useRef } from 'react';
import Intro from 'src/components/home/Intro';
import App from 'src/components/App';
import Footer from 'src/components/home/Footer';
import CustomHelmet from 'src/components/CustomHelmet';
import { graphql } from 'gatsby';
import { DefaultCourseQuery } from 'types/graphql-types';
import BackToTopButton from 'src/components/common/BackToTopButton';

interface Props {
  data: DefaultCourseQuery;
  location: any;
  history: any;
}

export default (props: Props) => {
  const title = 'Cauzl - Learn Causal Inference and Statistics';
  const overrideDescription = 'Learn causal inference and statistics';
  const description = 'Learn causal inference and statistics';
  const contentRef = useRef(null);

  return (
    <App history={props.history}>
      <style jsx={true}>{`
        .content {
          @p: .h100, .overflowScroll;
        }
      `}</style>
      <CustomHelmet title={title} description={description} overrideDescription={overrideDescription} />
      <div className="content" ref={contentRef}>
        <Intro introCourse={props.data.getDefaultCourse} />
        <Footer />
      </div>
      <BackToTopButton scrollableRef={contentRef} />
    </App>
  );
};

export const query = graphql`
  query DefaultCourse {
    getDefaultCourse {
      ...CourseData
    }
  }
`;
