import * as React from 'react';
import GraphQLConf from 'src/assets/icons/GraphQLConf';
import LinkArrow from 'src/assets/icons/LinkArrow';

export const Banner = () => (
  <div className="banner-container">
    <style jsx={true}>
      {`
        .banner-container {
          @p: .mb38;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 60px;
        }
        @media (max-width: 500px) {
          .banner-container {
            padding: 20px 30px 0;
            margin-bottom: -10px;
          }
        }
        .banner {
          background: rgb(244, 244, 244);
          border-radius: 20px;
          padding: 8px 15px;
          display: flex;
          align-items: center;
          color: black;
        }
        .title {
          font-size: 16px;
          margin-left: 8px;
        }
        .bold {
          font-weight: 600;
        }
        .link-arrow-wrapper {
          margin-left: 12px;
        }
      `}
    </style>
    <a className="banner" href="https://blog.cauzl.com" target="_blank">
      <GraphQLConf />
      <span className="title">
        Check out our <span className="bold">blog</span>!
      </span>
      <span className="link-arrow-wrapper">
        <LinkArrow />
      </span>
    </a>
  </div>
);
