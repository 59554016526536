import React from 'react';
import { navigate } from 'gatsby-link';
import cn from 'classnames';
import data from 'src/data/stacks';
import StackChooser from 'src/components/StackChooser';
import Subscribe from 'src/components/Subscribe';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useStaticQuery, graphql } from 'gatsby';
import { CourseProvider } from '../CourseProvider';
import CourseSteps from './CourseSteps';
import Steps from '../Steps/Steps';
import BendLine from '../Steps/BendLine';
import FakeLine from '../Steps/FakeLine';

interface Props {
  width?: number;
  light?: boolean;
}

export const FRONTEND_TUTORIALS_COUNT = data.filter(tutorial => tutorial.type === 'frontend').length - 1;

const Chooser = ({ light }: Props) => {
  // const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(BACKEND_INDEX);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { getDefaultCourse: defaultCourse, allCourse: courses } = useStaticQuery(graphql`
    query getChooserData {
      getDefaultCourse {
        id
      }
      allCourse {
        nodes {
          ...CourseData
          firstLesson: lessons(limit: 1) {
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const nonDefaultCourses = courses.nodes.filter((course: any) => defaultCourse.id !== course.id);

  const selectedCourse = nonDefaultCourses[selectedIndex];

  const selectStack = (index: number) => {
    if (index < 0 || index > Object.keys(nonDefaultCourses).length) {
      return;
    }
    setSelectedIndex(index);
  };

  const handleClickCurrentStack = () => {
    if (selectedCourse.comingSoon) return;
    const link = selectedCourse.firstLesson[0].fields.slug;
    navigate(link);
  };

  return (
    <div className={cn('steps-container', { light })}>
      <style jsx={true}>{`
        div.steps-container {
          @p: .bgDarkGreen, .white,.pb38;
        }
        .steps-content {
          @p: .flex;
        }
        .steps-content :global(.steps-description) {
          margin-top: 48px;
        }
        p {
          @p: .white;
        }
        h3 {
          @p: .fw6;
        }
        div h3.first-h3 {
          margin-top: 60px;
        }
        .steps-container :global(.steps-description) p {
          @p: .white30;
        }
        .duration {
          @p: .mt16, .mr38;
        }
        .fade-before-element {
          @p: .db;
          content: '';
          height: 48px;
          background-image: linear-gradient(to top, rgba(225, 225, 225, 0.2), #292e1e);
          width: 2px;
        }
        .choose-step-container {
          @p: .w100;
        }
        .before-choose {
          @p: .w100;
        }
        :global(.bend-line) {
          display: none;
        }
        @media (max-width: 500px) {
          :global(.bend-line) {
            display: block;
          }
          .description {
            display: none;
          }
          .before-choose {
            padding-left: 20px;
          }
          div.fade-before-element {
            @p: .dn;
          }
          h3.first-h3 {
            display: none;
          }
          .steps-content {
            padding: 0 30px;
          }
          .steps-content :global(.left-column) {
            display: none;
          }
          .bottom-chooser {
            @p: .relative;
            padding-top: 48px;
          }
          .bottom-chooser::after {
            content: '';
            top: -2px;
            left: 30px;
            position: absolute;
            display: block;
            height: 48px;
            width: 48px;
            background: none;
            border-top: 2px solid rgba(225, 225, 225, 0.2);
            border-left: 2px solid rgba(225, 225, 225, 0.2);
            border-top-left-radius: 500px;
          }
          .bottom-chooser::before {
            content: '';
            position: absolute;
            width: calc(50% - 128px);
            top: -2px;
            left: 80px;
            height: 2px;
            background: rgba(225, 225, 225, 0.2);
          }
        }
        .list-item {
          @p: .flex, .itemsCenter;
        }
        .steps-container :global(.dotted-list-item) {
          @p: .white;
        }
        .category-chooser {
          @p: .flex, .justifyCenter, .mt16;
        }
        .category {
          @p: .f14, .fw6, .white30, .ttu, .pointer, .tc;
        }
        .category.active {
          @p: .white70;
        }
        .steps-container :global(.stacks-item:not(.active)) :global(.graphql-elixir-1) {
          filter: grayscale(100%) brightness(1500%) !important;
        }
        .steps-container :global(.stacks-item.active) :global(.graphql-elixir-1) {
          filter: brightness(450%) !important;
        }
        .steps-container :global(.stacks-item:not(.active)) :global(.graphql-elixir-2),
        .steps-container :global(.stacks-item:not(.active)) :global(.graphql-ruby-2) {
          filter: grayscale(100%) brightness(1000%) !important;
        }
        .steps-container :global(.stacks-item.active) :global(.graphql-elixir-2),
        .steps-container :global(.stacks-item.active) :global(.graphql-ruby-2) {
          filter: brightness(180%) !important;
        }
        .center-container {
          @p: .flex, .justifyCenter;
        }
        .link {
          @p: .flex, .itemsCenter;
        }
        :global(.bend-line) {
          width: 50%;
        }
      `}</style>
      <style jsx={true} global={true}>{`
        .chooser-enter {
          opacity: 0.01;
        }
        .chooser-enter.chooser-enter-active {
          opacity: 1;
          transition: opacity 500ms ease-in;
        }
        .chooser-exit {
          opacity: 1;
        }
        .chooser-exit.chooser-exit-active {
          opacity: 0.01;
          transition: opacity 300ms ease-in;
        }
      `}</style>

      <div className="before-choose">
        <FakeLine dark={true} />
        <Steps
          first={false}
          last={false}
          dark={true}
          showDescription={true}
          description={{ title: 'List of all courses' }}
          steps={[
            {
              link: '/',
              title: 'Choose a course',
            },
          ]}
        />
      </div>

      <BendLine fade="end" className="bend-line" dark={true} />
      <StackChooser
        courses={nonDefaultCourses}
        selectedIndex={selectedIndex}
        onChangeSelectedIndex={selectStack}
        onClickCurrentStack={handleClickCurrentStack}
      />
      <BendLine fade="start" className="bend-line" reverse={true} dark={true} />
      <TransitionGroup>
        <CSSTransition key={selectedCourse.id} classNames="chooser" timeout={{ enter: 500, exit: 300 }}>
          {selectedCourse.comingSoon ? (
            <Subscribe audienceId={selectedCourse.audienceId} />
          ) : (
            <CourseProvider course={selectedCourse}>
              <CourseSteps first={false} dark={true} />
            </CourseProvider>
          )}
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default Chooser;
