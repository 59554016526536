import React from 'react';
import { AuthButton, customClaims } from 'src/utils/auth';
import { usePayment } from 'src/utils/Stripe';
import { useLatestActivity } from 'src/utils/hooks';
import LinkButton from 'src/components/common/LinkButton';
import SubscribePremiumButton from 'src/components/SubscribePremiumButton';

export default () => {
  const { loading: processingPayment } = usePayment();
  const renderPaymentButton = (user: any) =>
    processingPayment ? (
      <LinkButton rounded={true} size="large">
        Processing...
      </LinkButton>
    ) : (
      <SubscribePremiumButton user={user} />
    );
  const [latestActivity] = useLatestActivity();
  return (
    <AuthButton
      renderLoggedOut={login => (
        <LinkButton rounded={true} size="large" onClick={login}>
          Start Learning
        </LinkButton>
      )}
      renderLoading={() => (
        <LinkButton rounded={true} size="large">
          Just a second ...
        </LinkButton>
      )}
      renderLoggedIn={user => {
        const premium = user[customClaims.premium];
        return premium ? (
          latestActivity.pathname ? (
            <LinkButton rounded={true} size="large" to={latestActivity.pathname}>
              Pickup where you left off
            </LinkButton>
          ) : (
            <LinkButton rounded={true} size="large" to={'/'}>
              Start exploring
            </LinkButton>
          )
        ) : (
          renderPaymentButton(user)
        );
      }}
    />
  );
};
